import { differenceInDays, differenceInMinutes } from "date-fns"

import { TClockTypeMaybe } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { localizedDate } from "src/ui/LocalizedDate/localized-date"
import { extractHHMM } from "src/utils/l10n"

export function getLastUpdatedTime({
  clockType,
  lastHeardFromAt,
  timezone,
  online,
}: {
  clockType: TClockTypeMaybe
  lastHeardFromAt: string
  timezone?: string
  online: boolean
}) {
  const oneMinute = 1
  const oneHour = 60
  const within24Hours = oneHour * 24

  const diffInMinutes = differenceInMinutes(
    Date.now(),
    new Date(lastHeardFromAt)
  )

  const diffInDays = differenceInDays(Date.now(), new Date(lastHeardFromAt))

  const formattedTime = extractHHMM(
    new Date(localizedDate(lastHeardFromAt, clockType, timezone)),
    clockType
  )

  // Time within the minute
  if (diffInMinutes <= oneMinute) {
    const justNow = online
      ? langKeys.updated_just_now
      : langKeys.offline_just_now
    return { langKey: justNow, args: undefined }
  }

  // Time within the hour
  if (diffInMinutes >= oneMinute && diffInMinutes < oneHour) {
    const statusWithinHour = online
      ? langKeys.updated_min_ago
      : langKeys.offline_since_min_ago

    return { langKey: statusWithinHour, args: { "0": diffInMinutes } }
  }

  // Time within 24 hours but more than an hour
  if (diffInMinutes >= oneMinute && diffInMinutes < within24Hours) {
    const statusWithinDay = online
      ? langKeys.updated_at
      : langKeys.offline_since
    return { langKey: statusWithinDay, args: { "0": formattedTime } }
  }

  // Time one day ago
  if (diffInDays <= 1) {
    const statusAfterOneDay = online
      ? langKeys.updated_days_ago
      : langKeys.offline_since_days_ago

    return { langKey: statusAfterOneDay, args: { "0": diffInDays } }
  }

  // Time more than one day
  if (diffInDays > 1) {
    const statusMoreThanOneDay = online
      ? langKeys.updated_days_ago_plural
      : langKeys.offline_since_days_ago_plural

    return { langKey: statusMoreThanOneDay, args: { "0": diffInDays } }
  }

  // Default
  return null
}
